import React from 'react';
import Content3 from './Content3';
import aboutData from 'data/Saas/about.json';
import aboutDataRTL from 'data/Saas/about-rtl.json';

const About = ({ noPaddingTop, rtl }) => {
  const data = rtl ? aboutDataRTL : aboutData;

  return (
    <section className={`about ${noPaddingTop ? 'pt-0 pb-150':'section-padding'} style-5`} data-scroll-index="1">
      <Content3 texts={data.texts} rtl={rtl} number={aboutData.number} />
    </section>
  )
}

export default About